import { StreamerStatus } from '@pureweb/platform-sdk';
import { useEffect, useState } from 'react';

import usePxAuthContext from 'src/authentication/usePxAuthContext';
import PureWebAvatarModal from 'src/components/ReadyPlayerMe/PureWebAvatarModal';
import GameSocketContextProvider from 'src/contexts/GameSocketContextProvider';
import Background from 'src/features/PixelStreaming/components/Background/Background';
import ContentViewer from 'src/features/PixelStreaming/components/ContentViewer/ContentViewer';
import HUD from 'src/features/PixelStreaming/components/HUD';
import LaunchContainer from 'src/features/PixelStreaming/components/LaunchContainer/LaunchContainer';
import PointsDisplay from 'src/features/PixelStreaming/components/PointsDisplay';
import PointsOfInterest from 'src/features/PixelStreaming/components/PointsOfInterest';
import ServerBrowserModalContainer from 'src/features/PixelStreaming/components/ServerBrowserModalContainer';
import SettingsOverlay from 'src/features/PixelStreaming/components/SettingsOverlay';
import TutorialOverlay from 'src/features/PixelStreaming/components/TutorialOverlay/TutorialOverlay';
import ImageSlotViewer from '../../../PixelStreaming/components/ImageSlot/ImageSlotViewer';
import useDebugEmitUIInteract from 'src/features/PixelStreaming/hooks/useDebugEmitUIInteraction';
import useInitialPacket from 'src/features/PixelStreaming/hooks/useInitialPacket';
import useJoinGameSession from 'src/features/PixelStreaming/hooks/useJoinGameSession';
import useJoinSpace from 'src/features/PixelStreaming/hooks/useJoinSpace';
import useOpenExternalLink from 'src/features/PixelStreaming/hooks/useOpenExternalLink';
import useOpenMap from 'src/features/PixelStreaming/hooks/useOpenMap';
import useOpenModal from 'src/features/PixelStreaming/hooks/useOpenModal';
import useProfileUpdate from 'src/features/PixelStreaming/hooks/useProfileUpdate';
import useRefreshToken from 'src/features/PixelStreaming/hooks/useRefreshToken';
import useUserJoinAndExit from 'src/features/PixelStreaming/hooks/useUserJoinAndExit';
import EmbeddedView from 'src/features/PureWeb/components/EmbeddedView';
import PureWebAudioComponent from 'src/features/PureWeb/components/PureWebAudioComponent';
import PureWebContainer from 'src/features/PureWeb/components/PureWebContainer';
import PureWebContextProvider, { usePureWebContext } from 'src/features/PureWeb/contexts/PureWebContextProvider';
import UsageLimitReachedModal from 'src/features/Usage/components/UsageLimitReachedModal';
import UsageLimitReachedScreen from 'src/features/Usage/components/UsageLimitReachedScreen';
import UsageContextProvider, { useUsageContext } from 'src/features/Usage/contexts/UsageContextProvider';
import { IMetaverse3DPage } from 'src/interfaces/IPage';
import { useGetRandomGameLiftSession } from 'src/queries/gamelift';
import LoadingText from 'src/features/PixelStreaming/components/LoadingInfo/LoadingText';

import UploadContentModalContainer from '../../components/UploadContentModal/UploadContentModalContainer';

import styles from './Metaverse3DPage.module.scss';
import useEmojisBinding from 'src/features/PixelStreaming/hooks/useEmojisBinding';
import EmojiGestureSelection from 'src/features/NewVisitor/layouts/EmojiGestureSelection';

export interface Metaverse3DPageProps {
  page: IMetaverse3DPage;
}

function Metaverse3DPage({ page }: Metaverse3DPageProps) {
  const { metaverse, space } = page;
  const metaverseId = metaverse._id;

  const { hasUsage } = useUsageContext();

  const { mapName, purewebConfiguration } = space.environment;
  const { aliasId } = purewebConfiguration;

  const {
    data: randomGameLiftSession,
    error: getRandomGameLiftSessionError,
  } = useGetRandomGameLiftSession(metaverseId, aliasId, mapName);

  useEffect(() => {
    if (!randomGameLiftSession) return;
    setGameSessionId(randomGameLiftSession.GameSessionId);
  }, [randomGameLiftSession]);

  const {
    launch,
    isReady,
    isLaunching,
    isLaunched,
    isLocalPixelstreaming,
    videoStream,
    streamerStatus,
    emitter,
    status,
  } = usePureWebContext();

  const { user, authToken } = usePxAuthContext();

  const [gameSessionId, setGameSessionId] = useState('');
  useJoinGameSession(gameSessionId);
  useInitialPacket(
    metaverseId,
    undefined,
    authToken,
    user,
    gameSessionId,
  );
  useRefreshToken();
  useOpenExternalLink();
  useOpenModal();
  useJoinSpace(space._id);
  useProfileUpdate();
  useDebugEmitUIInteract();
  useUserJoinAndExit();
  useEmojisBinding(!!metaverseId && !!authToken && !!user);

  const openMap = mapName && (!aliasId || getRandomGameLiftSessionError);
  useOpenMap(openMap ? mapName : undefined);

  return (
    <>
      <PureWebContainer>
        {space.background && streamerStatus !== StreamerStatus.Connected && <Background source={space.background} />}
        <UsageLimitReachedScreen isOwner={false} logo={metaverse?.logo} />
        {
          hasUsage &&
          <EmbeddedView
            videoStream={videoStream}
            streamerStatus={streamerStatus}
            inputEmitter={emitter}
          >
            <HUD />
            <EmojiGestureSelection />
            <PointsDisplay />
            <PointsOfInterest />
            <ContentViewer metaverse={metaverse} />
            <ImageSlotViewer metaverse={metaverse} />
            <SettingsOverlay />
            <TutorialOverlay
              className={styles.tutorial}
              active={streamerStatus === StreamerStatus.Connected}
              ftueKey={`space:${space._id}`}
            />
          </EmbeddedView>
        }
        {
          isLaunching && !isLocalPixelstreaming &&
          <LoadingText />
        }
        {
          !isLaunched && isReady && !isLocalPixelstreaming && hasUsage &&
          <LaunchContainer
            logo={space?.logo}
            onEnter={launch}
            showBrowse={false}
            onBrowse={() => { }}
          />
        }
      </PureWebContainer>
      <PureWebAudioComponent />
      <UploadContentModalContainer
        metaverseId={metaverseId}
        isSiteAdmin={false}
      />
      <PureWebAvatarModal />
      <UsageLimitReachedModal isOwner={false} />
      <ServerBrowserModalContainer
        aliasId={aliasId}
        query={{ metaverseId, mapName }}
        onGameSessionIdChange={setGameSessionId}
      />
    </>
  );
}

export default function WrappedMetaverse3DPage({ page }: Metaverse3DPageProps) {
  const { projectId, modelId } = page.space.environment.purewebConfiguration;
  const { authToken } = usePxAuthContext();

  return (
    <UsageContextProvider metaverseId={page.metaverse._id}>
      <GameSocketContextProvider
        metaverseId={page.metaverse._id}
        authToken={authToken}>
        <PureWebContextProvider projectId={projectId} modelId={modelId}>
          <Metaverse3DPage page={page} />
        </PureWebContextProvider>
      </GameSocketContextProvider>
    </UsageContextProvider>
  );
}
